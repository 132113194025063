@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.App {
  background: rgb(20,20,24);
  background: radial-gradient(circle, rgba(20,20,24,0.9009978991596639) 0%, rgba(20,20,24,1) 100%);
}

.darkBlue {
  color: #141418;
}

.gray {
  color: #4B4A5E;
}

.white {
  color: #FFFFFF;
}

.lightblue {
  color: #F5F6FF;
}

.error {
  color: #DB4201;
}

.mainBlue {
  color: #141418;
}

.yellow {
  color: #FFD100;
}

.darkBlue-bg {
  background-color: #141418;
}

.gray-bg {
  background-color: #4B4A5E;
}

.white-bg {
  background-color: #FFFFFF;
}

.lightblue-bg {
  background-color: #F5F6FF;
}

.error-bg {
  background-color: #DB4201;
}

.mainBlue-bg {
  background-color: #141418;
}

.yellow-bg {
  background-color: #FFD100;
}

/* #tippy-2, #tippy-1, #tippy-3 {
  background-color: rgba(8, 9, 40, 0.8);
  color: #141418 !important;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 12px;
  margin-left: 60px;
} */

#tippy-2, #tippy-1, #tippy-3 {
  background-color: transparent;
  color: transparent !important;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 12px;
  margin-left: 60px;
}

.appear {
  opacity: 0;

  transition: opacity .7s;
}

.appear.active {
  opacity: 1;
}

.show-down {
  transform: translateY(-20px);
  opacity: 0;

  transition: transform .7s, opacity .7s;
}

.show-up {
  transform: translateY(20px);
  opacity: 0;

  transition: transform .7s, opacity .7s !important;
}

.show-up.active, .show-down.active {
  transform: translateY(0px);
  opacity: 1;
}

.show-order-2 {
  transition-delay: .2s !important;
}

.show-order-3 {
  transition-delay: .4s !important;
}

.show-order-4 {
  transition-delay: .6s !important;
}

.show-order-5 {
  transition-delay: .8s !important;
}

.show-order-6 {
  transition-delay: 1s !important;
}

.show-order-7 {
  transition-delay: 1.2s !important;
}

.show-order-8 {
  transition-delay: 1.4s !important;
}

.show-order-9 {
  transition-delay: 1.6s !important;
}

.show-order-10 {
  transition-delay: 1.8s !important;
}

.show-order-11 {
  transition-delay: 2s !important;
}

.show-order-12 {
  transition-delay: 2.2s !important;
}

.show-order-13 {
  transition-delay: 2.4s !important;
}

.show-order-14 {
  transition-delay: 2.6s !important;
}

.show-order-15 {
  transition-delay: 2.8s !important;
}

.show-order-16 {
  transition-delay: 3s !important;
}

.show-order-17 {
  transition-delay: 3.2s !important;
}

.show-order-18 {
  transition-delay: 3.4s !important;
}

.show-order-19 {
  transition-delay: 3.6s !important;
}

.show-order-20 {
  transition-delay: 3.8s !important;
}

.show-order-21 {
  transition-delay: 4s !important;
}
@font-face {
  font-family: "Sectra";
  src: url(/static/media/GT-Sectra-Fine-Regular-Trial.968d6ec2.otf) format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Sectra";
  src: url(/static/media/GT-Sectra-Fine-Medium-Trial.c6fb07fa.otf) format("opentype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Sectra";
  src: url(/static/media/GT-Sectra-Fine-Bold-Trial.1b80fc96.otf) format("opentype");
  font-weight: 800;
  font-display: swap;
}

* {
  color: white;
}

h1, h2, h3, h4, h5 {
  margin: 0px 0px;
}

.headline {
  font-family: 'Sectra';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 69px;
}

h1 {
  font-family: 'Sectra';
  font-weight: 800;
  font-size: 112px;
  line-height: 116px;
}

h2 {
  font-family: 'Sectra';
  font-weight: 800;
  font-size: 56px;
  line-height: 67px;
}

h3 {
  font-family: 'Sectra';
  font-weight: 800;
  font-size: 40px;
  line-height: 52px;
}

h4 {
  font-family: 'Sectra';
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.02em;
}

h5 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

p {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.01em;
  margin: 0;
}

label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-left: 20px;
  margin-bottom: 8px;
}

input:not([type=checkbox]), textarea {
  background: #F5F6FF;
  outline: none;
  border: none;
  border-radius: 100px; 
  width: 100%;
  min-height: 52px;
  padding: 18px 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: black;
}

textarea {
  min-height: 88px;
  border-radius: 16px;
}

input::-webkit-input-placeholder {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

input:-ms-input-placeholder {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

input::placeholder, .labeled {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.small {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.smaller {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.01em;
}

.button {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  outline: none;
}

button:focus {
  outline: none;
}

.meta-1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.03em;
}

.meta-2 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.meta-3 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.meta-4 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  opacity: 0.4;
}

.input-1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
}

.b2 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.b1 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}


@media (max-width: 1280px), (max-height: 800px) {
  .headline {
    font-size: 48px;
    line-height: 54px;
  }
}
