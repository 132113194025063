* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.App {
  background: rgb(20,20,24);
  background: radial-gradient(circle, rgba(20,20,24,0.9009978991596639) 0%, rgba(20,20,24,1) 100%);
}

.darkBlue {
  color: #141418;
}

.gray {
  color: #4B4A5E;
}

.white {
  color: #FFFFFF;
}

.lightblue {
  color: #F5F6FF;
}

.error {
  color: #DB4201;
}

.mainBlue {
  color: #141418;
}

.yellow {
  color: #FFD100;
}

.darkBlue-bg {
  background-color: #141418;
}

.gray-bg {
  background-color: #4B4A5E;
}

.white-bg {
  background-color: #FFFFFF;
}

.lightblue-bg {
  background-color: #F5F6FF;
}

.error-bg {
  background-color: #DB4201;
}

.mainBlue-bg {
  background-color: #141418;
}

.yellow-bg {
  background-color: #FFD100;
}

/* #tippy-2, #tippy-1, #tippy-3 {
  background-color: rgba(8, 9, 40, 0.8);
  color: #141418 !important;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 12px;
  margin-left: 60px;
} */

#tippy-2, #tippy-1, #tippy-3 {
  background-color: transparent;
  color: transparent !important;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 12px;
  margin-left: 60px;
}

.appear {
  opacity: 0;

  transition: opacity .7s;
}

.appear.active {
  opacity: 1;
}

.show-down {
  transform: translateY(-20px);
  opacity: 0;

  transition: transform .7s, opacity .7s;
}

.show-up {
  transform: translateY(20px);
  opacity: 0;

  transition: transform .7s, opacity .7s !important;
}

.show-up.active, .show-down.active {
  transform: translateY(0px);
  opacity: 1;
}

.show-order-2 {
  transition-delay: .2s !important;
}

.show-order-3 {
  transition-delay: .4s !important;
}

.show-order-4 {
  transition-delay: .6s !important;
}

.show-order-5 {
  transition-delay: .8s !important;
}

.show-order-6 {
  transition-delay: 1s !important;
}

.show-order-7 {
  transition-delay: 1.2s !important;
}

.show-order-8 {
  transition-delay: 1.4s !important;
}

.show-order-9 {
  transition-delay: 1.6s !important;
}

.show-order-10 {
  transition-delay: 1.8s !important;
}

.show-order-11 {
  transition-delay: 2s !important;
}

.show-order-12 {
  transition-delay: 2.2s !important;
}

.show-order-13 {
  transition-delay: 2.4s !important;
}

.show-order-14 {
  transition-delay: 2.6s !important;
}

.show-order-15 {
  transition-delay: 2.8s !important;
}

.show-order-16 {
  transition-delay: 3s !important;
}

.show-order-17 {
  transition-delay: 3.2s !important;
}

.show-order-18 {
  transition-delay: 3.4s !important;
}

.show-order-19 {
  transition-delay: 3.6s !important;
}

.show-order-20 {
  transition-delay: 3.8s !important;
}

.show-order-21 {
  transition-delay: 4s !important;
}