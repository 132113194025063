@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
  font-family: "Sectra";
  src: url("./assets/fonts/GT-Sectra-Fine-Regular-Trial.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Sectra";
  src: url("./assets/fonts/GT-Sectra-Fine-Medium-Trial.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Sectra";
  src: url("./assets/fonts/GT-Sectra-Fine-Bold-Trial.otf") format("opentype");
  font-weight: 800;
  font-display: swap;
}

* {
  color: white;
}

h1, h2, h3, h4, h5 {
  margin: 0px 0px;
}

.headline {
  font-family: 'Sectra';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 69px;
}

h1 {
  font-family: 'Sectra';
  font-weight: 800;
  font-size: 112px;
  line-height: 116px;
}

h2 {
  font-family: 'Sectra';
  font-weight: 800;
  font-size: 56px;
  line-height: 67px;
}

h3 {
  font-family: 'Sectra';
  font-weight: 800;
  font-size: 40px;
  line-height: 52px;
}

h4 {
  font-family: 'Sectra';
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.02em;
}

h5 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.02em;
}

p {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.01em;
  margin: 0;
}

label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-left: 20px;
  margin-bottom: 8px;
}

input:not([type=checkbox]), textarea {
  background: #F5F6FF;
  outline: none;
  border: none;
  border-radius: 100px; 
  width: 100%;
  min-height: 52px;
  padding: 18px 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: black;
}

textarea {
  min-height: 88px;
  border-radius: 16px;
}

input::placeholder, .labeled {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.small {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.smaller {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.01em;
}

.button {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  outline: none;
}

button:focus {
  outline: none;
}

.meta-1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.03em;
}

.meta-2 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.meta-3 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.meta-4 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  opacity: 0.4;
}

.input-1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
}

.b2 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.b1 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}


@media (max-width: 1280px), (max-height: 800px) {
  .headline {
    font-size: 48px;
    line-height: 54px;
  }
}